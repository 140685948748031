<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    ref="drawer"
    v-model="drawer"
    :expand-on-hover="expandOnHover"
    mobile-breakpoint="960"
    app
    clipped
    :mini-variant.sync="mini"
    mini-variant-width="60"
    width="280"
    v-bind="$attrs"
  >
    <v-list
      ref="listItems"
      :class="{ 'pl-2': !mini, 'pr-1': !mini, 'mr-2': !mini, 'pl-3': mini, 'pr-3': mini }"
      expand
      nav
    >
      <div></div>
      <template v-for="(item, i) in menuItems">
        <!-- <base-item-group v-if="item.children" :key="`group-${i}`" :item="item" :mini="mini" catalog> </base-item-group> -->
        <v-list-group v-if="item.children" :key="`group-${i}`" append-icon="mdi-menu-down">
          <template #activator>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold" v-text="item.title" />
            </v-list-item-content>
          </template>
          <template v-for="(child, ig) in item.children">
            <v-list-group v-if="child.children" :key="`groupc-${ig}`" append-icon="mdi-menu-down">
              <template #activator>
                <!-- <v-list-item-icon> </v-list-item-icon> -->
                <v-list-item-content class="ml-6">
                  <v-list-item-title class="font-weight-bold" v-text="child.title" />
                </v-list-item-content>
              </template>
              <template v-for="(chil, ic) in child.children">
                <base-item :key="`itemc-${ic}`" :item="chil" text :mini="mini" catalog />
              </template>
              <v-divider class="mb-0" />
            </v-list-group>
            <base-item v-else :key="`item-${ig}`" :item="child" text :mini="mini" catalog />
          </template>
          <v-divider class="mb-0" />
        </v-list-group>

        <base-item v-else :key="`item-${i}`" :item="item" :text="item.text" :mini="mini" catalog />
      </template>
      <div></div>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapState } from "vuex"
import axios from "../../../../plugins/axios"
export default {
  name: "DashboardCoreDrawer",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    monItems: [],
    mini: null,
    libraries: [],
    items: [
      {
        icon: "",
        title: "Home",
        to: "/"
      },
      {
        icon: "",
        title: "Public data",
        children: [
          {
            icon: "",
            title: "Products",
            children: [
              {
                icon: "",
                title: "Vendors search",
                to: "/vendors"
              },
              {
                icon: "",
                title: "Products search",
                to: "/product-list"
              },
              {
                icon: "",
                title: "Version search",
                to: "/version-search"
              }
            ]
          },
          {
            icon: "",
            title: "Vulnerabilities",
            children: [
              {
                icon: "",
                title: "Vulnerabilities By Date",
                to: "/browse-by-date"
              },
              {
                icon: "",
                title: "Vulnerabilities By Type",
                to: "/vulnerabilities-by-type"
              }
            ]
          },
          {
            icon: "",
            title: "Weaknesses",
            to: "/cwe"
          },
          {
            icon: "",
            title: "Exploits",
            children: [
              {
                icon: "",
                title: "Exploits",
                to: "/exploits"
              },
              {
                icon: "",
                title: "CVE by Exploits",
                to: ""
              }
            ]
          },
          {
            icon: "",
            title: "MUD files",
            to: "/mud-files"
          }
        ]
      },
      {
        icon: "",
        title: "Internal data",
        children: [
          {
            icon: "",
            title: "Profile information",
            children: [
              {
                icon: "",
                title: "Components and Dependencies",
                to: "/components"
              },
              {
                icon: "",
                title: "Software bugs",
                to: "/software-bugs"
              },
              {
                icon: "",
                title: "Use Case Profiles",
                to: "/use-case-profiles"
              }
            ]
          },
          {
            icon: "",
            title: "Runtime data",
            children: [
              {
                icon: "",
                title: "Sensors",
                to: "/runtime-data/sensors"
              },
              {
                icon: "",
                title: "Failures",
                to: "/runtime-data/failures"
              }
            ]
          }
        ]
      }

      // {
      //   icon: "",
      //   title: "Vendors",
      //   to: "/vendors"
      // },
      // {
      //   icon: "",
      //   title: "Products",
      //   to: "/product-list"
      // },
      // {
      //   icon: "",
      //   title: "Vulnerabilities By Date",
      //   to: "/browse-by-date"
      // },
      // {
      //   icon: "",
      //   title: "Vulnerabilities By Type",
      //   to: "/vulnerabilities-by-type"
      // },
      // {
      //   icon: "",
      //   title: "Version Search",
      //   to: "/version-search"
      // },
      // {
      //   icon: "",
      //   title: "Vulnerabilities Search",
      //   to: "/vulnerabilities-search"
      // },
      // {
      //   icon: "",
      //   title: "CWE",
      //   to: "/cwe"
      // },
      // {
      //   icon: "",
      //   title: "Sensors data",
      //   to: "/sensors-data"
      // },
      // {
      //   icon: "",
      //   title: "Applications bugs",
      //   to: "/software-bugs"
      // }
    ]
  }),
  metaInfo() {
    return {
      title: "BIECO DCT"
    }
  },
  computed: {
    ...mapState(["barColor", "barImage"]),
    appType() {
      return this.$store.getters.appType
    },
    biblioteca() {
      return this.$store.getters.libraryData.library.public_name
    },
    libraryCode() {
      return this.$store.getters.libraryData.library.code
    },
    accountEmail() {
      return this.$store.getters.userEmail
    },
    menuItems() {
      //this.$log("bar color ", this.barColor)
      const menu = []
      menu.push(...this.items.filter(itm => !itm.ctg))
      this.$log("menuuu ", menu)
      return menu
    },
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val)
      }
    }
  },
  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val)
    }
  },
  created() {
    this.$log("drawer created ", this.accountEmail)
    if (this.accountEmail) {
      axios
        .get('ebib_marc_cln?where={"fields.201.n.val":"' + encodeURI(this.accountEmail) + '"}&max_results=1&page=1')
        .then(res => {
          //this.$log("res ", res)
          if (res.status == 200) {
            if (res.data._items.length == 1) {
              this.$store.commit("userId", res.data._items[0]._id)
              //this.$store.commit("userFavorites", res.data._items[0].favorites)
              this.$store.commit("userBarcode", res.data._items[0].fields["500"][0].a[0].val)
            } else {
              this.$store.commit("userId", "")
              this.$store.commit("userBarcode", "")
            }
          }
        })
    }
  },
  methods: {}
}
</script>
